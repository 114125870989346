import Internal from '@layouts/Internal'

export default [
    {
        path: '/role-type',
        component: () => import('@layouts/components/EmptyComponent'),
        meta: {
            onMenu: true,
            onBreadcrumb: true,
            title: 'Tipos de papéis',
        },
        children: [
            {
                name: 'RoleTypeList',
                path: 'index',
                component: () => import('@modules/role_type/views/RoleTypeList'),
                meta: {
                    middlewareAuth: true,
                    onBreadcrumb: true,
                    title: 'Todos os tipos de papéis',
                    onMenu: true,
                    layout: Internal,
                },
            },
            {
                name: 'RoleTypeMatrix',
                path: 'matrix',
                component: () => import('@modules/role_type/views/RoleTypeMatrix.vue'),
                meta: {
                    middlewareAuth: true,
                    onBreadcrumb: true,
                    title: 'Matriz de permissões',
                    onMenu: true,
                    layout: Internal,
                },
            },
            {
                name: 'RoleTypeCreate',
                path: 'create',
                component: () => import('@modules/role_type/views/RoleTypeForm.vue'),
                meta: {
                    middlewareAuth: true,
                    onBreadcrumb: true,
                    title: 'Criar tipo de papel',
                    onMenu: true,
                    layout: Internal,
                },
            },
            {
                name: 'RoleTypeDetails',
                path: ':roleTypeId/details',
                props: true,
                component: () => import('@modules/role_type/views/RoleTypeForm.vue'),
                meta: {
                    middlewareAuth: true,
                    onBreadcrumb: true,
                    title: 'Editar tipo de papel',
                    layout: Internal,
                    alternativeMenu: 'RoleTypeList',
                },
            },
            {
                name: 'RoleTypePermission',
                path: ':roleTypeId/permission',
                props: true,
                component: () => import('@modules/role_type/views/RoleTypePermissionForm.vue'),
                meta: {
                    middlewareAuth: true,
                    onBreadcrumb: true,
                    title: 'Permissões do tipo de papel',
                    layout: Internal,
                    alternativeMenu: 'RoleTypeList',
                },
            },
            {
                path: '',
                redirect: {
                    name: 'RoleTypeList',
                },
            },
        ],
    },
]
